<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title style="width: 100%">Gestion de infraestructuras</v-toolbar-title>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            small
            bottom
            right
            absolute
            color="secondary"
            @click="showAddInfraestructuraDialog"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Infraestructuras</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="pt-0">
      <v-container class="py-0 ma-0" fluid ref="firstContainer">
        <v-row class="mt-0 no-spacing-row">
          <v-col cols="10">
            <v-autocomplete
              v-model="tableFilters"
              return-object
              :items="headers.filter(x => x.text !== 'Acciones')"
              :items-text="(h) => {return h.text}"
              item-title="text"
              label="Mostrar campos"
              closable-chips
              multiple
              clearable
              dense
              outlined
            >
              <template #selection="{ item, index }">
                <v-chip v-if="index < maxVisible" :key="item.value" small>
                  {{ item.text }}
                </v-chip>
                <span v-else-if="index===maxVisible" :key="index" small>
                  {{ computedHeaders.length - maxVisible - 1 }} más
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="resetFilters()"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-btn
              icon
              color="teal"
              @click="mostrarFilAv = !mostrarFilAv;increaseFirstContainerHeight()"
            >
              <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
              <v-icon v-else>mdi-filter-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="downloadExcelInfraestructuras()"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="no-spacing-row">
          <v-col cols="2">
            <v-text-field dense label="Búsqueda general" v-model="search" />
          </v-col>
          <v-col cols="1">
            <v-text-field dense label="Código" v-model="codigoSelected" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense label="Nombre" v-model="nombreSelected" />
          </v-col>
          <v-col cols="3">
            <v-select dense label="Municipio" v-model="municipioSelected" :items="municipiosFilter" item-text="NOMBRE" return-object clearable />
          </v-col>
          <v-col cols="3">
            <v-select dense label="Tipo" v-model="tipoSelected" :items="tiposFilter" item-text="NOMBRE" return-object clearable />
          </v-col>
          <v-col cols="1">
            <v-checkbox dense v-model="activoSelected" label="Activo" />
          </v-col>
        </v-row>
        <v-row class="no-spacing-row" v-show="mostrarFilAv">
          <v-col cols="3">
            <v-select dense label="Responsable" v-model="responsableSelected" :items="organismos" item-text="ORGANISMO" return-object clearable />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="demarcacionSelected"
              return-object
              :items="demarcacionItems()"
              :item-text="(demarcacion) => {return demarcacion.DEMARCACION}"
              clearable
              label="Demarcacion"
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-select dense label="Provincia" v-model="provinciaSelected" :items="provinciasFilter" item-text="LITERAL" return-object clearable />
          </v-col>
          <v-col cols="3">
            <v-select dense label="Categoria" v-model="categoriaSelected" :items="categorias" item-text="NOMBRE" return-object clearable />
          </v-col>
        </v-row>
        <v-row class="no-spacing-row">
          <v-col class="d-flex justify-center">
            <v-chip color="blue" dark>Hay {{ filteredItemsCount }} resultados</v-chip>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid ref="secondContainer">
        <v-data-table
          class="table"
          :headers="computedHeaders"
          :items="infraestructurasFilter"
          fixed-header
          disable-pagination
          hide-default-footer
          :style="{ height: `calc(${availableSpace}vh)` }"
        >
          <template #[`item.ACCIONES`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showEditInfraestructuraDialog(item)"
                >
                  <v-icon color="teal">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="toggleActiveInfraestructura(item)"
                >
                  <v-icon :color="item.BORRADO ? 'red': 'success'">{{ item.BORRADO ? 'mdi-domain-off': 'mdi-domain' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.BORRADO ? 'Activar': 'Desactivar' }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showAddImagenesDialog(item)"
                >
                  <v-icon color="teal">mdi-camera-outline</v-icon>
                </v-btn>
              </template>
              <span>Fotos</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
    <vx-dialog-add-edit-infraestructuras
      :show="showDialog"
      :isEdit="isEdit"
      :editedInfraestructura="editedInfraestructura"
      @cancelar="showDialog = false"
      @addInfraestructura="acceptAddInfraestructura"
      @editInfraestructura="acceptEditInfraestructura"
    />
    <vx-dialog-add-imagenes class="mt-4" :idInfraestructura="showDialogImagen?editedInfraestructura.ID_INFRAESTRUCTURA:null" :show="showDialogImagen" @cancelar="cancelAddImagenes" />
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DialogAddEditInfraestructuraVue from './DialogAddEditInfraestructura.vue'
import DialogAddImagenesInfraestructura from './DialogAddImagenesInfraestructura.vue'
import * as ExcelHelper from '@/helpers/ExcelHelper.js'
import { normalizeText, countFilteredItems, clearFilters } from '@/helpers/Utils.js'

export default {
  components: {
    'vx-dialog-add-edit-infraestructuras': DialogAddEditInfraestructuraVue,
    'vx-dialog-add-imagenes': DialogAddImagenesInfraestructura
  },
  data () {
    return {
      search: '',
      tableFilters: [],
      mostrarFilAv: false,
      codigoSelected: null,
      nombreSelected: null,
      tiposFilter: [],
      tipoSelected: null,
      categoriaSelected: null,
      responsableSelected: null,
      provinciaSelected: null,
      provinciasFilter: [],
      municipioSelected: null,
      municipiosFilter: [],
      demarcacionSelected: null,
      activoSelected: true,
      infraestructurasFilter: [],
      headers: [
        { text: 'Código', value: 'CODIGO', align: 'left', sortable: true, initialShow: true },
        { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, initialShow: true },
        { text: 'Categoría', value: 'CATEGORIA', align: 'left', sortable: true, initialShow: true },
        { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true, initialShow: true },
        { text: 'Responsable', value: 'ORGANISMO', align: 'left', sortable: true, initialShow: true },
        { text: 'Provincia', value: 'PROVINCIA', align: 'left', sortable: true, initialShow: true },
        { text: 'Municipio', value: 'MUNICIPIO.NOMBRE', align: 'left', sortable: true, initialShow: true },
        { text: 'Demarcación', value: 'DEMARCACION', align: 'left', sortable: true, initialShow: true },
        // { text: 'Fecha alta', value: 'FECHA_ALTA_PRETTY', align: 'left', sortable: true, initialShow: true },
        {
          text: 'Fecha alta',
          value: 'FECHA_ALTA_PRETTY',
          sortable: true,
          sort: (a, b) => {
            if (!a) return -1
            if (!b) return 1
            return new Date(a) - new Date(b)
          },
          align: 'left',
          initialShow: true
        },
        { text: 'Fecha baja', value: 'FECHA_BAJA_PRETTY', align: 'left', sortable: false },
        { text: 'Descripción', value: 'DESCRIPCION', align: 'left', sortable: true, initialShow: false },
        { text: 'Dirección', value: 'DIRECCION', align: 'left', sortable: true, initialShow: false },
        { text: 'Pos. X', value: 'X', align: 'left', sortable: true, initialShow: false },
        { text: 'Pos. Y', value: 'Y', align: 'left', sortable: true, initialShow: false },
        { text: 'MUP', value: 'MUP', align: 'left', sortable: true, initialShow: false },
        { text: 'ZEPA', value: 'ZEPA', align: 'left', sortable: true, initialShow: false },
        { text: 'LIC', value: 'LIC', align: 'left', sortable: true, initialShow: false },
        { text: 'ENP', value: 'ENP', align: 'left', sortable: true, initialShow: false },
        { text: 'WIFI', value: 'WIFI', align: 'left', sortable: true, initialShow: false },

        { text: 'Acciones', value: 'ACCIONES', align: 'center', width: '10%', sortable: false, initialShow: true }
      ],
      showDialog: false,
      showDialogImagen: false,
      isEdit: false,
      editedInfraestructura: {},

      availableSpace: 0,
      maxVisible: 9
    }
  },
  computed: {
    ...mapGetters('infraestructura', [
      'infraestructuras', 'tipos', 'categorias', 'municipios', 'provincias'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),
    ...mapGetters('demarcacion', [
      'demarcaciones'
    ]),

    filteredItemsCount () {
      return countFilteredItems(this.infraestructurasFilter, item => true)
    },

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Acciones') || this.tableFilters.includes(x))
    }
  },
  watch: {
    search () {
      this.filterInfraestructuras()
    },
    infraestructuras () {
      this.filterInfraestructuras()
    },
    provinciaSelected () {
      if (this.provinciaSelected) {
        this.municipiosFilter = [...this.municipios].filter(municipio => municipio.ID_PROVINCIA === this.provinciaSelected.ID_INE_PROVINCIA)
      }
      this.filterInfraestructuras()
    },
    municipioSelected () {
      if (this.municipioSelected) {
        this.provinciaSelected = this.municipioSelected.PROVINCIA
        this.demarcacionSelected = this.municipioSelected.DEMARCACION
      }
      this.filterInfraestructuras()
    },
    categoriaSelected () {
      if (this.categoriaSelected) {
        this.tiposFilter = [...this.tipos].filter(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA)
      } else {
        this.tiposFilter = [...this.tipos]
      }
      if (this.categoriaSelected != null && this.tipoSelected != null && this.tipoSelected.ID_INFRAESTRUCTURA_CATEGORIA !== this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA) {
        this.tipoSelected = null
      }
      this.filterInfraestructuras()
    },
    tipoSelected () {
      if (
        this.tipoSelected != null && (
          this.categoriaSelected === null ||
        this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA !== this.tipoSelected.ID_INFRAESTRUCTURA_CATEGORIA)
      ) {
        this.categoriaSelected = this.categorias.find(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.tipoSelected.ID_INFRAESTRUCTURA_CATEGORIA)
      }
      this.filterInfraestructuras()
    },
    responsableSelected () {
      this.filterInfraestructuras()
    },
    activoSelected () {
      this.filterInfraestructuras()
    },
    nombreSelected () {
      this.filterInfraestructuras()
    },
    demarcacionSelected () {
      if (this.demarcacionSelected) {
        this.municipiosFilter = [...this.municipios].filter(x => x.DEMARCACION.DEMARCACION === this.demarcacionSelected.DEMARCACION)
      } else {
        this.municipiosFilter = [...this.municipios]
      }
      this.filterInfraestructuras()
    },
    codigoSelected () {
      this.filterInfraestructuras()
    }
  },
  methods: {
    ...mapActions('infraestructura', ['fetchInfraestructuras', 'addInfraestructura', 'updateInfraestructura']),

    normalizeText (text) {
      return text ? text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
    },

    async filterInfraestructuras () {
      // 29/10 documentación en medios y personal pero el código es básicamente el mismo
      if (!this.infraestructuras || this.tipos.length === 0 || this.categorias.length === 0 || this.municipios.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 500))
        return this.filterInfraestructuras()
      }

      let infraestructurasFilter = this.infraestructuras
        .filter(x => x.BORRADO === !this.activoSelected)
        .map(infraestructura => ({
          ...infraestructura,
          FECHA_ALTA_PRETTY: infraestructura.FECHA_ALTA ? this.$date.parseDate(infraestructura.FECHA_ALTA).format('DD/MM/YYYY') : null,
          FECHA_BAJA_PRETTY: infraestructura.FECHA_BAJA ? this.$date.parseDate(infraestructura.FECHA_BAJA).format('DD/MM/YYYY') : null
        }))

      infraestructurasFilter = this.applyFilters(infraestructurasFilter)

      this.infraestructurasFilter = infraestructurasFilter
    },

    applyFilters (infraestructurasFilter) {
      infraestructurasFilter = this.filterSearch(infraestructurasFilter)
      infraestructurasFilter = this.filterCodigo(infraestructurasFilter)
      infraestructurasFilter = this.filterNombre(infraestructurasFilter)
      infraestructurasFilter = this.filterTiposAndCategorias(infraestructurasFilter)
      infraestructurasFilter = this.filterMunicipioAndProvincia(infraestructurasFilter)
      infraestructurasFilter = this.filterResponsable(infraestructurasFilter)
      infraestructurasFilter = this.filterDemarcacion(infraestructurasFilter)
      return infraestructurasFilter
    },

    filterSearch (infraestructurasFilter) {
      if (this.search && this.search !== '') {
        const normalizedSearch = this.normalizeText(this.search)
        const searchRegex = new RegExp(`\\b${normalizedSearch}\\b`, 'i')

        return infraestructurasFilter.filter(x =>
          (x.CODIGO && searchRegex.test(normalizeText(x.CODIGO))) ||
          (x.NOMBRE && searchRegex.test(normalizeText(x.NOMBRE))) ||
          (x.TIPO && searchRegex.test(normalizeText(x.TIPO))) ||
          (x.CATEGORIA && searchRegex.test(normalizeText(x.CATEGORIA))) ||
          (x.MUNICIPIO && searchRegex.test(normalizeText(x.MUNICIPIO))) ||
          (x.PROVINCIA && searchRegex.test(normalizeText(x.PROVINCIA))) ||
          (x.ORGANISMO && searchRegex.test(normalizeText(x.ORGANISMO))) ||
          (x.DEMARCACION && searchRegex.test(normalizeText(x.DEMARCACION))) ||
          (x.FECHA_ALTA && this.$date.formatDate(x.FECHA_ALTA, 'DD/MM/YYYY HH:mm').includes(this.search))
        )
      }
      return infraestructurasFilter
    },

    filterCodigo (infraestructurasFilter) {
      if (this.codigoSelected != null && this.codigoSelected !== '') {
        return infraestructurasFilter.filter(x =>
          x.CODIGO !== null && normalizeText(x.CODIGO).includes(normalizeText(this.codigoSelected))
        )
      }
      return infraestructurasFilter
    },

    filterNombre (infraestructurasFilter) {
      if (this.nombreSelected) {
        return infraestructurasFilter.filter(x =>
          x.NOMBRE && normalizeText(x.NOMBRE).includes(normalizeText(this.nombreSelected))
        )
      }
      return infraestructurasFilter
    },

    filterTiposAndCategorias (infraestructurasFilter) {
      if (this.tiposFilter.length === 0) {
        this.tiposFilter = [...this.tipos]
      }
      if (this.tipoSelected) {
        return infraestructurasFilter.filter(x =>
          x.ID_INFRAESTRUCTURA_TIPO === this.tipoSelected.ID_INFRAESTRUCTURA_TIPO
        )
      } else if (this.categoriaSelected) {
        const tipos = this.tipos.filter(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA)
        const tiposMap = tipos.map(tipo => tipo.ID_INFRAESTRUCTURA_TIPO)
        return infraestructurasFilter.filter(x => tiposMap.includes(x.ID_INFRAESTRUCTURA_TIPO))
      }
      return infraestructurasFilter
    },

    filterMunicipioAndProvincia (infraestructurasFilter) {
      if (!this.municipioSelected) {
        this.provinciasFilter = [...this.provincias]
      }
      if (!this.provinciaSelected && !this.demarcacionSelected) {
        this.municipiosFilter = [...this.municipios]
      }
      if (this.municipioSelected) {
        return infraestructurasFilter.filter(x =>
          x.ID_MUNICIPIO === this.municipioSelected.ID_MUNICIPIO
        )
      } else if (this.provinciaSelected) {
        const municipios = this.municipios.filter(x =>
          x.ID_PROVINCIA === this.provinciaSelected.ID_INE_PROVINCIA
        )
        const municipiosMap = municipios.map(municipio => municipio.ID_MUNICIPIO)
        return infraestructurasFilter.filter(x => municipiosMap.includes(x.ID_MUNICIPIO))
      }
      return infraestructurasFilter
    },

    filterResponsable (infraestructurasFilter) {
      if (this.responsableSelected) {
        return infraestructurasFilter.filter(x =>
          x.ID_ORGANISMO === this.responsableSelected.ID_ORGANISMO
        )
      }
      return infraestructurasFilter
    },

    filterDemarcacion (infraestructurasFilter) {
      if (this.demarcacionSelected) {
        return infraestructurasFilter.filter(x =>
          x.DEMARCACION === this.demarcacionSelected.DEMARCACION
        )
      }
      return infraestructurasFilter
    },
    acceptAddInfraestructura (infraestructura) {
      this.showDialog = false
      this.addInfraestructura(infraestructura)
    },
    acceptEditInfraestructura (infraestructura) {
      this.showDialog = false
      this.updateInfraestructura(infraestructura)
    },
    showAddInfraestructuraDialog () {
      this.editedInfraestructura = null
      this.isEdit = false
      this.showDialog = true
    },
    showEditInfraestructuraDialog (item) {
      this.editedInfraestructura = Object.assign({}, item)
      this.isEdit = true
      this.showDialog = true
    },
    showAddImagenesDialog (item) {
      this.editedInfraestructura = Object.assign({}, item)
      this.showDialogImagen = true
    },
    cancelAddImagenes () {
      this.showDialogImagen = false
    },
    toggleActiveInfraestructura (item) {
      if (item.BORRADO === false) {
        this.$store.dispatch('infraestructura/disableInfraestructura', item)
      } else {
        this.$store.dispatch('infraestructura/enableInfraestructura', item)
      }
    },
    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)

      this.activoSelected = true // 30/10 Activo seleccionado por defecto (puede que tarde un poco en cargar)

      const filters = {
        codigoSelected: null,
        nombreSelected: null,
        tipoSelected: null,
        categoriaSelected: null,
        responsableSelected: null,
        provinciaSelected: null,
        municipioSelected: null,
        demarcacionSelected: null,
        search: ''
      }

      clearFilters(filters)
      // Actualizar con valores iniciales
      Object.keys(filters).forEach(key => {
        this[key] = filters[key]
      })
      this.filterInfraestructuras()
    },
    demarcacionItems () {
      let collator = new Intl.Collator('es', { sensitivity: 'base' })

      let demarcacionesInfra = JSON.parse(JSON.stringify(this.demarcaciones))
      demarcacionesInfra = demarcacionesInfra.sort((a, b) => collator.compare(a.DEMARCACION, b.DEMARCACION))
      return demarcacionesInfra
    },
    increaseFirstContainerHeight () {
      // Forzar una actualización del DOM para reflejar la nueva altura
      this.$nextTick(() => {
        this.calculateAvailableSpace() // Recalcular el espacio disponible
      })
    },
    calculateAvailableSpace () {
      // Obtener la posición y altura del primer v-container
      const firstContainer = this.$refs.firstContainer
      const firstContainerRect = firstContainer.getBoundingClientRect()
      const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

      // Obtener la altura del viewport
      const viewportHeight = window.innerHeight

      // Calcular el espacio disponible en vh
      this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
    },
    downloadExcelInfraestructuras () {
      ExcelHelper.downloadJSON2CSV(this.computedHeaders.filter(x => x.value !== 'ACCIONES'), this.infraestructurasFilter)
    }
  },
  async mounted () {
    await this.fetchInfraestructuras()
    this.maxVisible = this.headers.filter(x => x.initialShow).length - 1
    this.resetFilters()

    // Calcular el espacio disponible al montar el componente
    this.calculateAvailableSpace()
    window.addEventListener('resize', this.calculateAvailableSpace)
  },
  beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
    window.removeEventListener('resize', this.calculateAvailableSpace)
  }
}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
}

.v-data-table {
  overflow: auto;
}

.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}

.table >>> thead > tr {
  vertical-align: top !important;
}

.no-spacing-row {
  height: fit-content;
  padding-bottom: 5px;
  margin-top: 0px;
}

.no-spacing-row >>>.v-text-field__details {
  display: none !important;
}

.no-spacing-row >>>.v-messages {
  display: none !important;
}

.no-spacing-row >>>.col {
  height: fit-content;
}

/* Estilo para limitar la altura del contenedor de selecciones y agregar scroll */
.v-input__selections {
  max-height: 20px;
  overflow-y: auto;
}

/* Footer ajustado */
.v-data-table >>> .v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: white;
}
</style>

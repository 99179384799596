<template>
  <v-container v-show="show">
    <div class="left-panel" id="miDiv">
      <v-card class="item my-2" width="70%">
        <v-text-field
          v-model="titulo"
          label="Titulo"
          type="text"
          style="width: 100%;"
          maxLength="55"
          hide-details
          outlined
          dense
        />
        <v-text-field
          v-model="paraje"
          label="Paraje"
          type="text"
          style="width: 100%;"
          maxLength="55"
          hide-details
          outlined
          dense
        />
        <v-text-field
          v-model="escala"
          label="Escala"
          type="number"
          style="width: 100%;"
          hide-details
          outlined
          dense
        />
        <v-btn color="primary" :disabled="isDisabled" @click="aplicar">Aplicar escala</v-btn>
      </v-card>
    </div>
    <div class="right-panel">
      <v-btn color="red" @click="cancelar">Cancelar</v-btn>
      <v-btn color="primary" @click="capturarPantalla">Capturar</v-btn>
    </div>
  </v-container>
</template>

<script>

import * as ArcGIS from '@/helpers/ArcGIS'
import * as PDFHelper from '@/helpers/PDFHelper'
export default {

  props: {
    show: Boolean,
    data: Object
  },
  data: () => ({
    escala: null,
    titulo: '',
    paraje: ''
  }),
  computed: {
    isDisabled () {
      return !this.escala
    }
  },
  methods: {
    async capturarPantalla () {
      const leftPanel = document.querySelector('.left-panel')
      const rightPanel = document.querySelector('.right-panel')

      const leftPanelWidth = leftPanel.getBoundingClientRect().width
      const rightPanelWidth = rightPanel.getBoundingClientRect().width

      const availableWidth = window.innerWidth - leftPanelWidth - rightPanelWidth

      // Calcular las coordenadas y dimensiones del área de captura
      const frameBounds = {
        x: leftPanelWidth, // Empieza donde termina el panel izquierdo
        y: 0, // Asumiendo que empieza desde la parte superior de la ventana
        width: availableWidth, // Ancho disponible entre los paneles. Resto el footer
        height: window.innerHeight - 64 - 25 // reso el footer y header
      }

      let escala = Math.round(ArcGIS.getMapView().scale)
      const newParams = this.getMarcoGeometry(leftPanelWidth)
      const newGraphic = await ArcGIS.createMarcoCapturaGraphic(newParams)
      const measure = await ArcGIS.measurePerimetros([newGraphic])
      const visiblePerimetros = await ArcGIS.getIncendioVisiblePerimetros([newGraphic])
      const measurePerimetros = await ArcGIS.measurePerimetros(visiblePerimetros)

      const [base64Img, base64ImgOverview] = await Promise.all([
        ArcGIS.capturarPantalla(frameBounds),
        ArcGIS.captureOverviewMap()
      ])

      await PDFHelper.pdfInforme(base64Img, base64ImgOverview, frameBounds, measure, measurePerimetros, escala, this.data.municipio, this.paraje, this.titulo, [newGraphic])

      this.escala = null
      this.titulo = ''
      this.paraje = ''
      this.cancelar()
    },
    getMarcoGeometry (panelWidth) {
      let mapViewOverview = ArcGIS.getMapViewOverviewGraphics()

      let xmax = mapViewOverview.items[0].geometry.xmax
      let xmin = mapViewOverview.items[0].geometry.xmin
      let ymax = mapViewOverview.items[0].geometry.ymax
      let ymin = mapViewOverview.items[0].geometry.ymin

      let width = xmax - xmin

      let newXMin = xmin + (panelWidth * width) / window.innerWidth
      let newXMax = xmax - (panelWidth * width) / window.innerWidth
      return { xmin: newXMin, xmax: newXMax, ymin: ymin, ymax: ymax }
    },
    aplicar () {
      ArcGIS.establecerEscala(this.escala)
    },
    cancelar () {
      this.$emit('exit')
    }
  }
}
</script>

<style scoped>

  .left-panel, .right-panel {
    /*calculo del width:display:
    proporcion final height/width = 0.91
    Altura = 100vh - 64px - 25px : el header y el footer
    Ancho = 100vw - 2(x = ancho de los divs laterales)*/
    position: absolute;
    top: 0;
    width: calc((91vw - (100vh - 64px - 25px)) / 1.82);
    height: calc(100vh - 64px - 25px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }

  .left-panel {
    left: 0;
  }

  .right-panel {
    right: 0;
  }

  .item {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item .v-btn  {
    width: 100%;
  }

  .v-btn, .v-text-field{
    margin: 15px 0;
    pointer-events: initial;
  }
</style>

<template>
  <v-dialog v-model="show" persistent max-width="100%" max-height="80%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Listado de incendios</v-toolbar-title>
    </v-toolbar>
    <div style="height: 80vh;">
      <v-card style="height: 100%;" class="d-flex flex-column">
        <v-card-text class="flex-grow-1 overflow-y-auto d-flex flex-column">
          <v-container class="py-0 ma-0 h-100" fluid>
            <v-row dense class="mt-2 no-spacing-row">
              <v-col cols="11">
                <v-autocomplete
                  v-model="tableFilters"
                  return-object
                  :items="headers.filter(x => x.text !== 'Ocultar/Mostrar')"
                  :items-text="(h) => {return h.text}"
                  item-title="text"
                  label="Mostrar campos"
                  multiple
                  clearable
                  dense
                  outlined
                >
                  <template #selection="{ item, index }">
                    <v-chip v-if="index < maxVisible" :key="item.value" small>
                      {{ item.text }}
                    </v-chip>
                    <span v-else-if="index === maxVisible" :key="index" small>
                      {{ computedHeaders.length - maxVisible - 1 }} más
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn icon color="teal" @click="resetFilters()">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="teal"
                  @click="mostrarFilAv = !mostrarFilAv; increaseFirstContainerHeight()"
                >
                  <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
                  <v-icon v-else>mdi-filter-minus-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense class="mt-0 no-spacing-row">
              <v-col cols="3">
                <v-text-field label="Nombre" v-model="nombreSelected" clearable outlined dense @blur="filterFechaEmergencia" />
              </v-col>

              <v-col cols="3">
                <v-text-field label="Municipio" v-model="nombreEmergenciaSelected" outlined dense />
              </v-col>
              <v-col cols="2">
                <v-select label="Estado" v-model="estadoSelected" :items="emergenciaEstadoTipo" item-text="EMERGENCIA_ESTADO" return-object clearable outlined dense />
              </v-col>
              <v-col cols="2">
                <v-select
                  label="Nivel"
                  v-model="nivelSelected"
                  :items="tiposIncendioNivel"
                  item-text="INCENDIO_NIVEL"
                  return-object
                  clearable
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="2">
                <v-select label="Mostrar" v-model="ocultosSelected" :items="['Visibles', 'Ocultos']" return-object clearable outlined dense />
              </v-col>
            </v-row>
            <v-row dense class="mt-0 no-spacing-row" v-show="mostrarFilAv">
              <v-col cols="3" class="d-flex flex-row" style="gap: 8px;">
                <v-menu ref="fechaInit" v-model="showFechaInit" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaInitFormatted"
                      @click:clear="fechaInit = null"
                      label="Fecha desde"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaInit"
                    @input="$refs.fechaInit.save(fechaInit)"
                    locale="es-ES"
                    :max="fechaEnd"
                  />
                </v-menu>

                <v-menu ref="fechaEnd" v-model="showFechaEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaEndFormatted"
                      label="Fecha hasta"
                      @click:clear="fechaEnd = null"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaEnd"
                    @input="$refs.fechaEnd.save(fechaInit)"
                    locale="es-ES"
                    :min="fechaInit"
                  />
                </v-menu>
              </v-col>

              <v-col cols="3">
                <v-text-field label="Localidad" v-model="localidadEmergenciaSelected" outlined dense />
              </v-col>

              <v-col cols="2">
                <v-select
                  label="Tipo Detección"
                  v-model="tipoDeteccionSelected"
                  :items="tiposDeteccion"
                  item-text="DETECCION"
                  return-object
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row class="no-spacing-row">
              <v-col>
                <span class="subtitle-1 black--text">Nº de incendios: {{ emergenciasFilter.length }}</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0 ma-0 flex-grow-1 overflow-y-auto" fluid>
            <div
              class="d-flex flex-column"
              style="max-height: 100%;"
            >
              <v-data-table
                style="overflow: auto !important"
                :headers="computedHeaders"
                :items="emergenciasFilter"
                item-key="ID_EMERGENCIA"
                fixed-header
                hide-default-footer
                disable-pagination
              >
                <template #no-data>
                  <p>Datos no disponibles.</p>
                </template>

                <template #[`item.FECHA_CREACION`]="{ item }">
                  <span>{{ item.FECHA_CREACION | formatDate }}</span>
                </template>

                <template #[`item.ESTADO`]="{ item }">
                  <span>{{ item.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO }}</span>
                </template>

                <template #[`item.TIPO_DETECCION`]="{ item }">
                  <span>{{ item.INCENDIO && item.INCENDIO.TIPO_DETECCION.DETECCION ? item.INCENDIO.TIPO_DETECCION.DETECCION : "" }}</span>
                </template>

                <template #[`item.OCULTO`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="item.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE !== 4 && item.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE !== 5" @click.stop.prevent="mostrarOcultarIncendio(item)">
                        <v-icon v-show="!item.OCULTO" color="black">mdi-eye-off</v-icon>
                        <v-icon v-show="item.OCULTO" color="black">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span v-show="!item.OCULTO">Ocultar</span>
                    <span v-show="item.OCULTO">Mostrar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalizeText, countFilteredItems, clearFilters } from '../../helpers/Utils.js'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    showFechaInit: false,
    showFechaEnd: false,
    fechaInit: null,
    fechaEnd: null,
    nombreEmergenciaSelected: null,
    localidadEmergenciaSelected: null,
    estadoSelected: null,
    tipoDeteccionSelected: null,
    nivelSelected: null,
    nombreSelected: null,
    tableFilters: [],
    ocultosSelected: null,

    headers: [
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, width: '10%', initialShow: true },
      { text: 'Municipio', value: 'MUNICIPIO', align: 'left', sortable: true, initialShow: true },
      { text: 'Localidad', value: 'LOCALIDAD', align: 'left', sortable: true, initialShow: true },
      { text: 'Fecha de inicio', value: 'FECHA_CREACION', align: 'left', width: '20%', sortable: true, initialShow: true },
      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true, initialShow: true },
      { text: 'Tipo Detección', value: 'TIPO_DETECCION', align: 'left', width: '10%', sortable: true, initialShow: true },
      { text: 'Ocultar/Mostrar', value: 'OCULTO', align: 'center', sortable: false }
    ],

    emergenciasFilter: [],
    maxVisible: 10,

    mostrarFilAv: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposIncendioEstado', 'tiposDeteccion', 'tiposIncendioNivel'
    ]),

    ...mapGetters('emergencia', [
      'emergenciaEstadoTipo'
    ]),

    incendios () {
      return this.$store.getters['incendio/incendiosAll']
    },

    emergencias () {
      return this.$store.getters['emergencia/emergencias']
    },

    fechaInitFormatted () {
      let date = this.$date.parseDate(this.fechaInit, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },
    fechaEndFormatted () {
      let date = this.$date.parseDate(this.fechaEnd, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },

    filteredItemsCount () {
      return countFilteredItems(this.incendiosFilter, () => true)
    },

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Ocultar/Mostrar') || this.tableFilters.includes(x))
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.emergenciasFilter = this.emergencias
        this.filterFechaEmergencia()
        this.resetFilters()
      }
    },
    incendios () {
      this.filterFechaEmergencia()
    },
    emergencias () {
      this.filterFechaEmergencia()
    },
    fechaInit () {
      this.filterFechaEmergencia()
    },
    fechaEnd () {
      this.filterFechaEmergencia()
    },
    nombreEmergenciaSelected () {
      this.filterFechaEmergencia()
    },
    localidadEmergenciaSelected () {
      this.filterFechaEmergencia()
    },
    estadoSelected () {
      this.filterFechaEmergencia()
    },
    tipoDeteccionSelected () {
      this.filterFechaEmergencia()
    },
    nivelSelected () {
      this.filterFechaEmergencia()
    },
    nombreSelected () {
      this.filterFechaEmergencia()
    },
    ocultosSelected () {
      this.filterFechaEmergencia()
    }
  },

  methods: {
    filterFechaEmergencia () {
      if (this.fechaInit) {
        this.emergenciasFilter = this.emergencias.filter(x => x.FECHA_CREACION >= this.fechaInit)
      } else {
        this.emergenciasFilter = this.emergencias
      }

      if (this.fechaEnd) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x => x.FECHA_CREACION <= this.fechaEnd)
      }

      if (this.nombreEmergenciaSelected) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x => x.MUNICIPIO.toLowerCase().includes(this.nombreEmergenciaSelected.toLowerCase()))
      }

      if (this.localidadEmergenciaSelected) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x => x.LOCALIDAD.toLowerCase().includes(this.localidadEmergenciaSelected.toLowerCase()))
      }

      if (this.nombreEmergenciaSelected) {
        const normalizedSearch = normalizeText(this.nombreEmergenciaSelected)
        this.emergenciasFilter = this.emergenciasFilter.filter(x =>
          normalizeText(x.MUNICIPIO).includes(normalizedSearch)
        )
      }

      if (this.nombreSelected) {
        const normalizedSearch = normalizeText(this.nombreSelected)
        this.emergenciasFilter = this.emergenciasFilter.filter(x =>
          normalizeText(x.NOMBRE).includes(normalizedSearch)
        )
      }

      if (this.localidadIncendioSelected) {
        const normalizedSearch = normalizeText(this.localidadIncendioSelected)
        this.emergenciasFilter = this.emergenciasFilter.filter(x =>
          normalizeText(x.LOCALIDAD).includes(normalizedSearch)
        )
      }

      if (this.estadoSelected) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x => x.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.EMERGENCIA_ESTADO === this.estadoSelected.EMERGENCIA_ESTADO)
      }

      if (this.tipoDeteccionSelected) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x =>
          x.INCENDIO && x.INCENDIO.ID_TIPO_DETECCION === this.tipoDeteccionSelected.ID_TIPO_DETECCION
        )
      }

      if (this.nivelSelected) {
        this.emergenciasFilter = this.emergenciasFilter.filter(x =>
          x.ID_TIPO_INCENDIO_NIVEL === this.nivelSelected.ID_TIPO_INCENDIO_NIVEL
        )
      }
      if (this.ocultosSelected) {
        let boolean = this.ocultosSelected === 'Ocultos'
        this.emergenciasFilter = this.emergenciasFilter.filter(x => x.OCULTO === boolean)
      }
    },

    mostrarOcultarIncendio (emergencia) {
      let oculto = !emergencia.OCULTO

      this.$store.dispatch('emergencia/updateEmergencia', {
        ID_EMERGENCIA: emergencia.ID_EMERGENCIA,
        OCULTO: oculto
      })
    },

    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)

      const filters = {
        fechaInit: this.fechaInit,
        fechaEnd: this.fechaEnd,
        nombreIncendioSelected: this.nombreIncendioSelected,
        localidadIncendioSelected: this.localidadIncendioSelected,
        estadoSelected: this.estadoSelected,
        tipoDeteccionSelected: this.tipoDeteccionSelected,
        nivelSelected: this.nivelSelected,
        nombreSelected: null
      }

      clearFilters(filters)

      // Actualizar con valores iniciales
      Object.keys(filters).forEach(key => {
        this[key] = filters[key]
      })

      this.filterFechaEmergencia() // Volver a aplicar los filtros con los valores reiniciados
    },

    increaseFirstContainerHeight () {
      this.$nextTick(() => {
        this.calculateAvailableSpace()
      })
    },

    calculateAvailableSpace () {
      if (this.$refs.firstContainer) {
        const firstContainer = this.$refs.firstContainer
        const firstContainerRect = firstContainer.getBoundingClientRect()
        const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

        // Obtener la altura del viewport
        const viewportHeight = window.innerHeight

        // Calcular el espacio disponible en vh
        this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
      }
    },

    mounted () {
      this.increaseFirstContainerHeight()
      this.resetFilters()
      this.maxVisible = this.headers.filter(x => x.initialShow).length - 1

      // Calcular el espacio disponible al montar el componente
      this.calculateAvailableSpace()

      // Actualizar el espacio disponible si la ventana se redimensiona
      window.addEventListener('resize', this.calculateAvailableSpace)
    },

    beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
      window.removeEventListener('resize', this.calculateAvailableSpace)
    },

    cerrar () {
      this.$emit('cerrar')
    }
  }
}
</script>

<style scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
      max-height: 500px;
      overflow: auto;
    }

    .v-data-table >>> .v-data-table__wrapper { /* Header fijo */
      overflow: unset;
    }

    .v-sheet.v-card {
      border-radius: 0;
    }

    .no-spacing-row {
      height: fit-content;
      padding-bottom: 5px;
      margin-top: 0px;
    }

    .no-spacing-row ::v-deep(.v-text-field__details) {
      display: none !important;
    }

    .no-spacing-row ::v-deep(.col) {
      height: fit-content;
    }
</style>

import request from '../request'

export default {
  getEmergencias: function () {
    return request.get('emergencias')
  },

  getAllIncendios: function () {
    return request.get('lista_incendios')
  },

  addPlanOperaciones: function (data) {
    return request.post('plan_operaciones', data)
  },

  addIncendio: function (data) {
    return request.post('/incendios', data)
  },

  changeSectoresEmergencia: function (data) {
    return request.put(`sector/${data.ID_EMERGENCIA}`, data)
  },

  getTiposIncendioNivel: function () {
    return request.get('tipo_incendio_nivel')
  },

  updateEmergencia: function (data) {
    return request.put('/emergencias', data)
  },

  eliminarIncendio: function (data) {
    return request.put(`eliminar_incendio/${data.ID_EMERGENCIA}`)
  },

  addPerimetro: function (data) {
    return request.post('perimetro', data)
  },

  addEstadoEmergencia: function (data) {
    return request.post('/emergencia_estado', data)
  },

  updateEstadoEmergencia: function (data) {
    return request.put('/emergencia_estado', data)
  },

  deleteEstadoIncendio: function (data) {
    return request.delete('emergencia_estado', { data: data })
  },

  crearEmergencia: function (data) {
    return request.post('/emergencias', data)
  },

  addMediosEmergencia: function (data) {
    return request.post('medio_sector', data)
  },

  editMedioEmergencia: function (data) {
    return request.put(`medio_sector/${data.ID_MEDIO_SECTOR}`, data)
  },
  deleteMedioEmergencia: function (data) {
    return request.delete(`medio_sector/${data.ID_MEDIO_SECTOR}`, { data: data })
  },

  editPlanificacionMedio: function (data) {
    return request.put(`planificacion_medio/${data.PLANIFICACION.ID_PLANIFICACION_MEDIO}`, data.PLANIFICACION)
  },

  getEmergenciasTipo () {
    return request.get('/emergenciasTipo')
  },

  editAlineacionesCampbell: function (data) {
    return request.put(`alineaciones_campbell/${data.ID_ALINEACIONES_CAMPBELL}`, data)
  },

  getEmergenciaEstadoTipo () {
    return request.get('/emergencia_estado_tipo')
  },

  getIncendio () {
    return request.get('incendios')
  }
}

<template>
  <div>
    <v-text-field v-model="search" class="mt-2" label="Buscar" append-icon="mdi-magnify" />

    <v-btn-toggle v-model="categoriasSelecionadas" color="primary" rounded multiple>
      <v-btn :key="tipo.index" v-for="tipo in categoriasMedios" small>{{ tipo.text }}</v-btn>
    </v-btn-toggle>

    <v-data-table
      v-model="mediosSelected" :headers="headers" :items="mediosFiltrados" item-key="ID_MEDIO" :search="search" height="630px" :item-class="getRowColor" @click:row="zoomMedio"
      group-by="ID_EMERGENCIA" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" disable-pagination hide-default-footer :headers-length="6" style="white-space: normal"
    >
      <template #no-data>
        <p>Datos no disponibles.</p>
      </template>

      <template #[`item.MEDIO`]="{ item }">
        <span>{{ item.MEDIO.substr(0,15) + (item.MEDIO.length > 15 ? '.' : '') }}</span>
      </template>

      <template #[`item.ULT_POSICION`]="{ item }">
        <span>{{ item.ULT_POSICION ? item.ULT_POSICION.FECHA : '' | formatDate }}</span>
      </template>

      <template #[`item.ESTADO`]="{ item }">
        <span>{{ nombreEstado(item.ESTADO) }}</span>
      </template>

      <template #[`item.FECHA`]="{ item }">
        <span>{{ item.FECHA ? item.FECHA : item.FECHA_BASE | formatDate }}</span>
      </template>

      <template #[`item.ID_EMERGENCIA_ANTERIOR`]="{ item }">
        <span>{{ $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA_ANTERIOR) ? $store.getters['emergencia/getEmergenciaByID'](item.ID_EMERGENCIA_ANTERIOR).MUNICIPIO : 'No ha actuado' }}</span>
      </template>

      <template #[`group.header`]="{ group, toggle, isOpen }">
        <td @click="toggle" :colspan="headers.length" style="cursor: pointer;">
          <v-btn x-small icon :ref="group" :style="getGroupHeaderColor (group)">
            <v-icon v-if="!isOpen">mdi-plus</v-icon>
            <v-icon v-else>mdi-minus</v-icon>
          </v-btn>
          <span>{{ $store.getters['emergencia/getEmergenciaByID'](group) ? $store.getters['emergencia/getEmergenciaByID'](group).MUNICIPIO : 'Sin asignar' }}</span>
        </td>
      </template>
      <template #[`item.ACCIONES`]="{ item }">
        <td>
          <v-layout>
            <!-- Editar -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn fab icon small v-bind="attrs" v-on="on" @click="editMedio(item)" :disabled="disabledByRol() || item.ESTADO === 0">
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar medio</span>
            </v-tooltip>
          </v-layout>
        </td>
      </template>
    </v-data-table>

    <vx-dialog-edit-medio :show="showDialogEditMedio" :emergencia="emergenciaMedio" :medioSector="medioEdit" @aceptar="acceptEditMedioIncendio" @cancelar="cancelEditMedioIncendio" @deleteMedioEmergencia="deleteMedioEmergencia" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constants from '../../helpers/constants'
import * as ArcGIS from '../../helpers/ArcGIS'

import DialogEditMedio from './leftmenu/DialogEditMedio'

export default {

  components: {
    'vx-dialog-edit-medio': DialogEditMedio
  },

  props: {
    show: Boolean,
    emergencia: Object
  },

  data: () => ({
    isValid: false,

    sortBy: 'ULT_POSICION',
    sortDesc: true,

    headers: [
      { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true },
      { text: 'Clase', value: 'CLASE', align: 'left', sortable: true },
      {
        text: 'Fecha ult. posición',
        value: 'ULT_POSICION',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        }
      },
      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true },
      {
        text: 'Fecha ult. estado',
        value: 'FECHA',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a) return -1
          if (!b) return 1
          return new Date(a) - new Date(b)
        }
      },
      { text: 'Ultima emergencia', value: 'ID_EMERGENCIA_ANTERIOR', align: 'left', sortable: true },
      { text: '', value: 'ACCIONES', align: 'center', sortable: false }
    ],

    search: '',

    categoriasMedios: [],

    mediosSelected: [],

    mediosOrder: [],

    mediosFiltrados: [],

    estadoMedios: [],

    categoriasSelecionadas: [],

    emergenciaMedio: null,

    medioEdit: {},

    showDialogEditMedio: false
  }),

  computed: {
    ...mapGetters('medio', [
      'clasesMedio'
    ]),

    disableAdd () {
      return this.mediosSelected.length === 0 || !this.isValid
    },

    medios () {
      let medios = JSON.parse(JSON.stringify(this.$store.getters['medio/medios']))

      medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)

      let emergencias = this.$store.getters['emergencia/emergencias'] // TODO: esta chapuza puede dar problemas aun mayores si se oculta el incendio antes de que acabe el descanso

      /** TODO: Esta cosa super cutre se hace para tener la fecha de fin descanso mas tardia en DialogAddMedios */
      medios.forEach((m) => { // Añadir descanso a cada medio de la lista
        let fechaDescanso = this.$date.parseDate('2018-01-01 00:00:00.000')
        emergencias.forEach((emergencia) => {
          for (let i = 0; i < emergencia.SECTORES.length; i++) {
            let sector = emergencia.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.ID_MEDIO.equalsIgnoreCase(m.ID_MEDIO)) {
                if (medioSector.FECHA_FIN_DESCANSO) {
                  let newFechaDescanso = this.$date.parseDate(medioSector.FECHA_FIN_DESCANSO)

                  if (newFechaDescanso > fechaDescanso) {
                    fechaDescanso = newFechaDescanso
                  }
                }
              }
            }
          }
        })

        if (fechaDescanso.format('YYYY/MM/DD HH:mm') !== '2018/01/01 00:00') {
          m.FECHA_FIN_DESCANSO = fechaDescanso
        }
      })

      return medios
    },

    sectores () {
      let sectores = []

      if (this.emergencia) {
        sectores = this.emergencia.SECTORES
      }

      return sectores
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.setCategoriasMedio()

        this.orderMedios()
      }
    },

    medios () {
      this.orderMedios()
    },

    categoriasSelecionadas () {
      this.mediosFiltrados = this.mediosOrder.filter((a) => {
        let categoria = this.categoriasMedios.find(element => element.value === a.CLASE)

        return categoria && this.categoriasSelecionadas.includes(categoria.index)
      })
    }
  },

  methods: {
    setCategoriasMedio () {
      this.categoriasMedios = []
      this.categoriasSelecionadas = []

      this.clasesMedio.forEach((element, index) => {
        let categoria = {
          text: element.NOMBRE, value: element.NOMBRE, id: element.ID_MEDIO_CLASE, align: 'left', sortable: true, index: index
        }

        this.categoriasMedios.push(categoria)

        this.categoriasSelecionadas.push(index)
      })
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },

    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },

    getRowColor (medio) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (medio.ESTADO) {
        case constants.estadosMedioEmergencia.AVISO:
          rowClass = 'medioAvisoResumen'
          break
        case constants.estadosMedioEmergencia.ACTUANDO:
          rowClass = 'medioLlegadaIncResumen'
          break
        case constants.estadosMedioEmergencia.REGRESO:
          rowClass = 'medioSalidaResumen'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescansoResumen'
      }

      return rowClass
    },

    getGroupHeaderColor (incendio) {
      let estilo = {
        cursor: 'pointer',
        'background-color': this.$store.getters['emergencia/getEmergenciaByID'](incendio) ? this.$store.getters['emergencia/getEmergenciaByID'](incendio).ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR : 'green'
      }

      return estilo
    },

    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))
      /* for (let i = 0; i < mediosOrder.length; i++) { // TODO: revisar que es esto
        let m = mediosOrder[i]
        if (m.ESTADO === 3) {
          m.ESTADO = 0
        }
      } */

      mediosOrder.sort(function (a, b) {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      this.mediosOrder = mediosOrder
      this.mediosFiltrados = mediosOrder
    },

    zoomMedio (value) {
      let medio = this.mediosOrder.find(x => x.ID_MEDIO === value.ID_MEDIO)
      if (medio && medio.ULT_POSICION) {
        let center = {
          target: [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
          zoom: 17
        }

        ArcGIS.setCenterMap(center)
      }
    },

    nombreEstado (estado) {
      switch (estado) {
        case 0:
          return 'No asignado'

        case 1:
          return 'Avisado'

        case 2:
          return 'Llegada a incendio'

        case 3:
          return 'Salida del incendio'

        case 4:
          return 'Llegada a base'
      }
    },

    // Dialogo editar medio
    acceptEditMedioIncendio (medioEdit) {
      this.showDialogEditMedio = false
      this.$store.dispatch('emergencia/editMedioEmergencia', medioEdit)
      this.medioEdit = {}
    },

    cancelEditMedioIncendio () {
      this.showDialogEditMedio = false
      this.medioEdit = {}
    },

    deleteMedioEmergencia (medioDelete) {
      this.showDialogEditMedio = false
      this.medioEdit = {}
      this.$store.dispatch('emergencia/deleteMedioEmergencia', medioDelete)
    },

    editMedio (medio) {
      this.emergenciaMedio = JSON.parse(JSON.stringify(this.$store.getters['emergencia/getEmergenciaByID'](medio.ID_EMERGENCIA_ANTERIOR)))

      let sectores = this.emergenciaMedio.SECTORES
      sectores.forEach(element => {
        for (let i = 0; i < element.MEDIOS.length; i++) {
          if (element.MEDIOS[i].ID_MEDIO === medio.ID_MEDIO) {
            let sector = element.MEDIOS[i]
            sector.MEDIO = sector.MEDIO?.MEDIO
            this.medioEdit = sector
          }
        }
      })

      this.showDialogEditMedio = true
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }
}
</script>

<style >
  .medioAvisoResumen {
    background-color: #8EB4E3 !important;
  }
  .medioLlegadaIncResumen {
    background-color: #F14D5A !important;
  }
  .medioSalidaResumen {
    background-color: #FFC000 !important;
  }
  .medioDescansoResumen {
    background-color: #AAAAAA !important;
  }

</style>

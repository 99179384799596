<template>
  <v-container v-show="showCards" id="emergenciasList" :class="{'mobile': $vuetify.breakpoint.xs}">
    <v-row dense v-for="(emergencia, i) in emergenciasOrder" :key="i">
      <v-col>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-card
              v-bind="attrs" v-on="on" :color="getColor(emergencia)" elevation="6" dark @click="selectEmergencia(emergencia)"
            >
              <v-card-title class="text-h7 pt-0 pl-3">
                {{ emergencia.NOMBRE || emergencia.LOCALIDAD }}
                <v-spacer />

                <v-btn
                  class="mx-0"
                  icon
                  v-show="emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE === 4 || emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.VALUE === 5"
                  @click.stop.prevent="ocultarEmergencia(emergencia)"
                >
                  <v-icon color="black">mdi-eye-off</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-subtitle :class="{'text-right': $vuetify.breakpoint.smAndUp, 'pb-0': true}">
                {{ emergencia.FECHA_CREACION | formatDate }}
              </v-card-subtitle>
            </v-card>
          </template>
          <span>Detalles emergencia</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showCards: Boolean,
    incendio: Object
  },
  computed: {
    ...mapGetters('emergencia', [
      'emergenciasFiltradas'
    ]),

    ...mapGetters('incendio', [
      'tiposIncendioNivel'
    ]),
    emergenciasOrder () {
      return this.emergenciasFiltradas
    }
  },

  methods: {
    selectEmergencia (emergencia) {
      this.$emit('selectEmergencia', emergencia.ID_EMERGENCIA)
    },

    ocultarEmergencia (emergencia) {
      this.$store.dispatch('emergencia/updateEmergencia', {
        ID_EMERGENCIA: emergencia.ID_EMERGENCIA,
        OCULTO: true
      })
    },

    getColor (emergencia) {
      // Color del nivel del incendio
      if (emergencia.INCENDIO?.NIVELES?.[0]?.TIPO_INCENDIO_NIVEL.COLOR) {
        return emergencia.INCENDIO.NIVELES[0].TIPO_INCENDIO_NIVEL.COLOR
      }

      // Color del estado si no hay niveles
      if (emergencia.ESTADOS?.[0]?.EMERGENCIA_ESTADO_TIPO?.COLOR) {
        return emergencia.ESTADOS[0].EMERGENCIA_ESTADO_TIPO.COLOR
      }

      // Color predeterminado
      return 'red'
    }

  }
}
</script>

<style scoped>
  #emergenciasList {
    position: absolute;
    top: 20px;
    left: 15px;
    overflow-y: auto;
    width: 300px;
    max-height: calc(100% - 100px);
  }

  .mobile {
    top: 65px !important;
    width: 170px !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
</style>

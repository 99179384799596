<template>
  <v-dialog v-model="show" persistent max-width="750px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="medio" label="Medio *" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col>
                <v-text-field v-model="alias" label="Alias" outlined dense />
              </v-col>
              <v-col>
                <v-select v-model="tipoSelected" label="Tipo *" :items="sortedTiposMedio" item-text="TIPO" item-value="TIPO" return-object :rules="[globalRules.required]" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="correo1" label="Correo 1" outlined dense />
              </v-col>
              <v-col>
                <v-text-field v-model="correo2" label="Correo 2" outlined dense />
              </v-col>
              <v-col>
                <v-autocomplete label="Base" :items="bases()" item-text="NOMBRE" item-value="NOMBRE" v-model="baseSelected" return-object outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Tlf. externo" type="number" maxlength="9" v-model="tlfExterno" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="Tlf. externo 2" type="number" maxlength="9" v-model="tlfExterno2" outlined dense />
              </v-col>
              <v-col>
                <v-autocomplete label="Responsable" :items="organismosItems()" item-text="ORGANISMO" item-value="ORGANISMO" v-model="organismoSelected" return-object outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Tlf. interno" type="number" maxlength="9" v-model="tlfInterno" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="ISSI" v-model="issi" type="number" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="ISSI Pos." v-model="issiPos" type="number" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Fabricante" v-model="fabricanteSelected" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="Nº Matrícula" v-model="matriculaSelected" outlined dense />
              </v-col>
              <v-col v-if="showCheckboxGuardiasDinamicas">
                <v-autocomplete return-object :disabled="guardiasDinamicasCheckbox" label="Personal asociado" clearable v-model="personalSelected" :items="filterPersonal()" :item-text="(p) => {return p.NOMBRE + ' ' + p.APELLIDOS;}" item-value="ID_PERSONAL" dense outlined single-line autocomplete hide-details @input="disableUsedMedios()" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Nº de componentes" v-model="numeroComponentes" type="number" min="0" outlined dense />
              </v-col>
              <v-col v-show="isMedioAereo">
                <v-text-field label="Velocidad máxima (km/h)" v-model="velocidadMaxima" type="number" maxlength="9" outlined dense />
              </v-col>
              <v-col>
                <v-menu ref="menuHoraEntrada" v-model="showHoraEntrada" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="horaEntrada" label="Hora entrada *" prepend-icon="mdi-clock-time-four-outline" :rules="[globalRules.required]" readonly v-bind="attrs" v-on="on" outlined dense />
                  </template>
                  <v-time-picker v-model="horaEntrada" v-show="showHoraEntrada" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraEntrada = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraEntrada.save(horaEntrada)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu ref="menuHoraSalida" v-model="showHoraSalida" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="horaSalida" label="Hora salida *" prepend-icon="mdi-clock-time-four-outline" :rules="[globalRules.required]" readonly v-bind="attrs" v-on="on" outlined dense />
                  </template>
                  <v-time-picker v-model="horaSalida" v-show="showHoraSalida" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraSalida = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraSalida.save(horaSalida)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row v-if="showCheckboxMedioDespachable || showCheckboxGuardiasDinamicas">
              <v-col v-if="showCheckboxMedioDespachable">
                <v-checkbox v-model="despachableCheckbox" label="Despachable" hide-details outlined dense />
              </v-col>
              <v-col v-if="showCheckboxMedioDespachable">
                <v-checkbox v-model="activoCheckbox" label="Activo" hide-details outlined dense />
              </v-col>
              <v-col v-if="showCheckboxGuardiasDinamicas">
                <v-checkbox v-model="guardiasDinamicasCheckbox" @change="personalSelected = null" label="Guardias dinámicas" hide-details outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-textarea rows="1" v-model="descripcion" label="Descripción" outlined dense />
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from '@/helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedMedio: Object
  },

  data: () => ({
    isValid: false,

    medio: null,
    velocidadMaxima: null,
    tlfInterno: null,
    tlfExterno: null,
    alias: null,

    showHoraEntrada: false,
    horaEntrada: null,
    showHoraSalida: false,
    horaSalida: null,

    tipoSelected: null,
    baseSelected: null,
    personalSelected: null,
    organismoSelected: null,
    showCheckboxMedioDespachable: false,
    showCheckboxGuardiasDinamicas: false,
    despachableCheckbox: true,
    activoCheckbox: true,
    guardiasDinamicasCheckbox: false,

    numeroComponentes: null,
    issi: null,
    issiPos: null,
    correo1: null,
    correo2: null,
    tlfExterno2: null,
    fabricanteSelected: null,
    matriculaSelected: null,
    descripcion: null
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio', 'medios'
    ]),
    ...mapGetters('personal', [
      'personales'
    ]),
    ...mapGetters('personalMedioTipo', [
      'personalMedioTipo'
    ]),
    ...mapGetters('infraestructura', [
      'infraestructuras'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),
    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },

    isMedioAereo () {
      return this.tipoSelected && this.tipoSelected.CLASE === constants.maestras.CLASE.MEDIO_AEREO.NOMBRE
    },
    sortedTiposMedio () {
      return [...this.tiposMedio].sort((a, b) =>
        constants.collator.compare(a.TIPO, b.TIPO)
      )
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.medio = this.editedMedio.MEDIO
          this.alias = this.editedMedio.ALIAS

          this.tipoSelected = this.tiposMedio.find(x => x.TIPO === this.editedMedio.TIPO)

          this.velocidadMaxima = this.editedMedio.VELOCIDAD_MAX

          this.tlfInterno = this.editedMedio.TELEFONO_INTERNO
          this.tlfExterno = this.editedMedio.TELEFONO_EXTERNO

          this.baseSelected = this.bases().find(x => x.ID_INFRAESTRUCTURA === this.editedMedio.ID_INFRAESTRUCTURA)
          this.horaEntrada = this.editedMedio.HORA_ENTRADA
          this.horaSalida = this.editedMedio.HORA_SALIDA
          this.personalSelected = this.personales.find(x => x.ID_PERSONAL === this.editedMedio.ID_PERSONAL)

          if (this.showCheckboxMedioDespachable) {
            this.despachableCheckbox = this.editedMedio.DESPACHABLE
          }

          if (this.showCheckboxMedioDespachable) {
            this.activoCheckbox = this.editedMedio.ACTIVO
          }

          if (this.showCheckboxGuardiasDinamicas) {
            this.guardiasDinamicasCheckbox = this.editedMedio.GUARDIAS_DINAMICAS
          }

          this.numeroComponentes = this.editedMedio.NUMERO_COMPONENTES ? this.editedMedio.NUMERO_COMPONENTES : 0
          this.issi = this.editedMedio.ISSI

          this.issiPos = this.editedMedio.ISSI_POS
          this.correo1 = this.editedMedio.CORREO_1
          this.correo2 = this.editedMedio.CORREO_2
          this.tlfExterno2 = this.editedMedio.TELEFONO_EXTERNO_2
          this.fabricanteSelected = this.editedMedio.FABRICANTE
          this.matriculaSelected = this.editedMedio.NUMERO_MATRICULA
          this.organismoSelected = this.organismosItems().find(x => x.ORGANISMO === this.editedMedio.ORGANISMO)
          this.descripcion = this.editedMedio.DESCRIPCION
        } else {
          this.despachableCheckbox = true
          this.activoCheckbox = true
          this.guardiasDinamicasCheckbox = false
        }
      }
    }
  },

  methods: {
    disableUsedMedios () {

    },

    organismosItems () {
      let organismosItems = JSON.parse(JSON.stringify(this.organismos))
      organismosItems = organismosItems.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
      return organismosItems
    },

    bases () {
      let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => infra.INFRAESTRUCTURA_TIPO.IS_BASE)))
      bases = bases.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
      return bases
    },

    filterPersonal () {
      let logicMedioPersonal = this.personalMedioTipo.filter(logic => (logic.ID_MEDIO_TIPO === this.editedMedio?.ID_MEDIO_TIPO))
      let personalesFiltrados = this.personales.filter(personal => logicMedioPersonal.find(y => y.ID_PERSONAL === personal.ID_PERSONAL))
      personalesFiltrados = personalesFiltrados.map(personal => {
        personal.disabled = this.medios.some(medio => medio.ID_PERSONAL === personal.ID_PERSONAL)
        return personal
      })
      return personalesFiltrados
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {
      let medio = this.isEdit ? JSON.parse(JSON.stringify(this.editedMedio)) : {}
      if (this.tipoSelected.CLASE === constants.maestras.CLASE.MEDIO_AEREO.NOMBRE) {
        medio.VELOCIDAD_MAX = this.velocidadMaxima
      } else {
        medio.VELOCIDAD_MAX = null
      }

      medio.MEDIO = this.medio
      medio.ALIAS = this.alias
      medio.ISSI = this.issi
      medio.ISSI_POS = this.issiPos
      medio.CORREO_1 = this.correo1
      medio.CORREO_2 = this.correo2
      medio.TELEFONO_INTERNO = this.tlfInterno
      medio.TELEFONO_EXTERNO = this.tlfExterno
      medio.TELEFONO_EXTERNO_2 = this.tlfExterno2
      medio.FABRICANTE = this.fabricanteSelected
      medio.NUMERO_MATRICULA = this.matriculaSelected
      medio.NUMERO_COMPONENTES = this.numeroComponentes
      medio.HORA_ENTRADA = this.horaEntrada
      medio.HORA_SALIDA = this.horaSalida
      medio.DESCRIPCION = this.descripcion

      // despachable default true hasta implementar logica
      medio.DESPACHABLE = true

      // Tipo / Categoria
      medio.TIPO = this.tipoSelected.TIPO
      medio.CATEGORIA = this.tipoSelected.CATEGORIA
      medio.ID_MEDIO_TIPO = this.tipoSelected.ID_MEDIO_TIPO
      medio.ID_MEDIO_CLASE = this.tipoSelected.ID_MEDIO_CLASE

      // Base
      medio.BASE = this.baseSelected ? this.baseSelected.NOMBRE : null
      medio.ID_INFRAESTRUCTURA = this.baseSelected ? this.baseSelected.ID_INFRAESTRUCTURA : null

      // Organismo - Responsable
      medio.ORGANISMO = this.organismoSelected ? this.organismoSelected.ORGANISMO : null
      medio.ID_ORGANISMO = this.organismoSelected ? this.organismoSelected.ID_ORGANISMO : null

      if (this.showCheckboxMedioDespachable) {
        medio.DESPACHABLE = this.despachableCheckbox
      }
      if (this.showCheckboxMedioDespachable) {
        medio.ACTIVO = this.activoCheckbox
      }

      if (this.showCheckboxGuardiasDinamicas) {
        medio.ID_PERSONAL = !this.personalSelected ? null : this.personalSelected.ID_PERSONAL
        medio.GUARDIAS_DINAMICAS = this.guardiasDinamicasCheckbox
        medio.NOMBRE = medio.ID_PERSONAL ? (this.personalSelected.NOMBRE + ' ' + this.personalSelected.APELLIDOS) : null
      }

      if (this.isEdit) {
        this.$emit('editMedio', medio)
      } else {
        medio.ID_MEDIO = this.$uuid.createUUID()
        medio.ESTADO = constants.estadosMedioEmergencia.DISPONIBLE
        this.$emit('addMedio', medio)
      }

      this.$refs.form.reset()
    }
  },
  mounted () {
    this.showCheckboxMedioDespachable = constants.marcarMediosDespachables
    this.showCheckboxGuardiasDinamicas = constants.verPanelGuardias
  }
}
</script>

<style scoped>
::v-deep.v-list-item--disabled{
  color: #ff9c9c !important
}
::v-deep.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #00b116 !important
}
</style>

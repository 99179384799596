<template>
  <v-dialog v-model="show" persistent max-width="750px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="nombre" label="Nombre *" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="apellidos" label="Apellidos *" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="alias" label="Alias" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="dni" label="DNI" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="identificador" label="Identificador" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="login" label="Login" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="telefono" label="Teléfono" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="correo" label="Correo" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="issi" label="ISSI" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="telefono2" label="Teléfono 2" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="correo2" label="Correo 2" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="issiPos" label="ISSI Pos" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tituloProfesionalSelected"
                  return-object
                  :items="tituloProfesionalItems()"
                  :item-text="(titulo) => {return titulo.TITULO_PROFESIONAL}"
                  item-title="TITULO_PROFESIONAL"
                  item-value="ID_TITULO_PROFESIONAL"
                  label="Título profesional *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="responsableSelected"
                  return-object
                  :items="responsableItems()"
                  :item-text="(responsable) => {return responsable.ORGANISMO}"
                  item-title="ORGANISMO"
                  item-value="ID_ORGANISMO"
                  label="Responsable *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tipoPersonalSelected"
                  return-object
                  :items="tipoPersonalItems()"
                  :item-text="(tipo) => {return tipo.PERSONAL_TIPO}"
                  item-title="PERSONAL_TIPO"
                  item-value="ID_PERSONAL_TIPO"
                  label="Tipo de personal *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="baseSelected"
                  return-object
                  :items="basesItems()"
                  :item-text="(base) => {return base.NOMBRE}"
                  item-title="NOMBRE"
                  item-value="ID_INFRAESTRUCTURA"
                  label="Base *"
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="tiposSelected"
                  return-object
                  :items="tiposMediosItems()"
                  :item-text="(tipo) => {return tipo.TIPO}"
                  item-title="TIPO"
                  item-value="ID_TIPO"
                  label="Medios asociados"
                  closable-chips
                  multiple
                  clearable
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="fu" label="FU" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-textarea rows="1" v-model="descripcion" label="Descripción" outlined dense />
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="isFuncionario" label="Funcionario" outlined dense />
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="isDespachable" label="Despachable" outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar </v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inflate } from 'pdfmake/build/pdfmake'
import { mapGetters } from 'vuex'
import constants from '@/helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedPersonal: Object
  },
  data () {
    return {
      nombre: null,
      apellidos: null,
      alias: null,
      telefono: null,
      telefono2: null,
      issi: null,
      issiPos: null,
      dni: null,
      login: null,
      correo: null,
      correo2: null,
      identificador: null,
      tituloProfesionalSelected: null,
      responsableSelected: null,
      baseSelected: null,
      tipoPersonalSelected: null,
      fu: null,
      descripcion: null,
      isFuncionario: false,
      isDespachable: false,
      isValid: false,
      tiposSelected: []
    }
  },
  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),
    ...mapGetters('personalMedioTipo', [
      'personalMedioTipo'
    ]),
    ...mapGetters('infraestructura', [
      'infraestructuras', 'tipos'
    ]),
    ...mapGetters('tituloProfesional', [
      'titulosProfesionales'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),
    ...mapGetters('personalTipo', [
      'tiposPersonal'
    ]),
    ...mapGetters('estadosMedio', [
      'estados'
    ]),

    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.nombre = this.editedPersonal.NOMBRE
          this.apellidos = this.editedPersonal.APELLIDOS
          this.alias = this.editedPersonal.ALIAS
          this.telefono = this.editedPersonal.TELEFONO
          this.issi = this.editedPersonal.ISSI
          this.identificador = this.editedPersonal.IDENTIFICADOR
          this.dni = this.editedPersonal.DNI
          this.login = this.editedPersonal.LOGIN
          this.telefono2 = this.editedPersonal.TELEFONO2
          this.correo = this.editedPersonal.CORREO
          this.correo2 = this.editedPersonal.CORREO2
          this.tituloProfesionalSelected = this.editedPersonal.TITULO_PROFESIONAL
          this.responsableSelected = this.editedPersonal.ORGANISMO
          this.baseSelected = this.editedPersonal.INFRAESTRUCTURA
          this.tipoPersonalSelected = this.editedPersonal.PERSONAL_TIPO
          this.fu = this.editedPersonal.FU
          this.issiPos = this.editedPersonal.ISSI_POS
          this.descripcion = this.editedPersonal.DESCRIPCION
          this.isFuncionario = this.editedPersonal.FUNCIONARIO
          this.isDespachable = this.editedPersonal.DESPACHABLE
          this.tiposSelected = this.personalMedioTipo.filter(y => y.ID_PERSONAL === this.editedPersonal.ID_PERSONAL).map(x => x.MEDIO_TIPO)
        } else {
          this.isFuncionario = false
          this.isDespachable = false
        }
      }
    }

  },
  methods: {
    tituloProfesionalItems () {
      let titulosProf = JSON.parse(JSON.stringify(this.titulosProfesionales))
      titulosProf = titulosProf.sort((a, b) => constants.collator.compare(a.TITULO_PROFESIONAL, b.TITULO_PROFESIONAL))
      return titulosProf
    },
    responsableItems () {
      let responsables = JSON.parse(JSON.stringify(this.organismos))
      responsables = responsables.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
      return responsables
    },
    basesItems () {
      let tiposBasesIds = this.tipos.filter(tipoInfra => tipoInfra.IS_BASE).map(tipoInfra => tipoInfra.ID_INFRAESTRUCTURA_TIPO)
      let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => tiposBasesIds.includes(infra.ID_INFRAESTRUCTURA_TIPO))))
      bases = bases.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
      return bases
    },
    tipoPersonalItems () {
      let tiposPersonales = JSON.parse(JSON.stringify(this.tiposPersonal))
      tiposPersonales = tiposPersonales.sort((a, b) => constants.collator.compare(a.PERSONAL_TIPO, b.PERSONAL_TIPO))
      return tiposPersonales
    },
    tiposMediosItems () {
      let tiposMedioItems = JSON.parse(JSON.stringify(this.tiposMedio))
      tiposMedioItems = tiposMedioItems.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
      return tiposMedioItems
    },
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },
    aceptar () {
      let personal = this.isEdit ? JSON.parse(JSON.stringify(this.editedPersonal)) : {}
      if (!this.isEdit) {
        personal.ID_PERSONAL = this.$uuid.createUUID()
        let activo = this.estados.find(estado => estado.MEDIO_CLASE.NOMBRE === 'PERSONAL' && estado.NOMBRE === 'ACTIVO')
        personal.ID_MEDIO_ESTADO = activo.ID_MEDIO_ESTADO
        personal.MEDIO_ESTADO = activo
        personal.ACTIVO = true
        personal.BORRADO = false
      }

      personal.NOMBRE = this.nombre
      personal.APELLIDOS = this.apellidos
      personal.ALIAS = this.alias
      personal.TELEFONO = this.telefono
      personal.ISSI = this.issi
      personal.IDENTIFICADOR = this.identificador
      personal.DNI = this.dni
      personal.LOGIN = this.login
      personal.TELEFONO2 = this.telefono2
      personal.CORREO = this.correo
      personal.CORREO2 = this.correo2
      personal.TITULO_PROFESIONAL = this.tituloProfesionalSelected
      personal.ID_TITULO_PROFESIONAL = this.tituloProfesionalSelected.ID_TITULO_PROFESIONAL
      personal.ORGANISMO = this.responsableSelected
      personal.ID_ORGANISMO = this.responsableSelected.ID_ORGANISMO
      personal.INFRAESTRUCTURA = this.baseSelected
      personal.ID_INFRAESTRUCTURA = this.baseSelected.ID_INFRAESTRUCTURA
      personal.PERSONAL_TIPO = this.tipoPersonalSelected
      personal.ID_PERSONAL_TIPO = this.tipoPersonalSelected.ID_PERSONAL_TIPO
      personal.FU = this.fu
      personal.ISSI_POS = this.issiPos
      personal.DESCRIPCION = this.descripcion
      personal.FUNCIONARIO = this.isFuncionario
      personal.DESPACHABLE = this.isDespachable

      this.tiposSelected = this.personalMedioTipo.filter(y => y.ID_PERSONAL === personal.ID_PERSONAL).map(x => x.MEDIO_TIPO)
      personal.TIPOS_MEDIOS = this.tiposSelected.map(tipo => ({
        ID_PERSONAL_MEDIO_TIPO: this.$uuid.createUUID(),
        ID_PERSONAL: personal.ID_PERSONAL,
        ID_MEDIO_TIPO: tipo.ID_MEDIO_TIPO
      }))
      if (this.isEdit) {
        this.$emit('editPersonal', personal)
      } else {
        this.$emit('addPersonal', personal)
      }

      this.$refs.form.reset()
    }
  }
}
</script>

<style>

</style>
